<template>
  <div>
    <div>
      <div class="mainTitle">采购申请</div>
      <div class="content">
        部门根据实际需求，提交采购申请。<br />
        采购明细支持手工添加和文件导入，简化大量明细的添加过程。
      </div>
      <img src="@/assets/productcore/caigou1.png" alt="" />
    </div>
    <div>
      <div class="mainTitle">采购计划</div>
      <div class="content">
        收集一段时间内采购申请，统一下发，集中采购;<br />
        计划备注提供紧急标签，紧急需求优先处理。
      </div>
      <img src="@/assets/productcore/caigou2.png" alt="" />
    </div>
    <div>
      <div class="mainTitle">合同管理</div>
      <div class="content">
        招标项目签订合同，系统统一管理。合同模板可下载，合同详情可上传。
      </div>
      <img src="@/assets/productcore/caigou3.png" alt="" />
    </div>
        <div>
      <div class="mainTitle">统计分析</div>
      <div class="content">
      采购金额、数量统计、申请部门统计、招标情况统计、图形样例分析、下钻细则分析。
      </div>
      <img src="@/assets/productcore/caigou4.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "purchase-manage",
};
</script>

<style scoped>
.mainTitle {
  font-size: 30px;
  color: #333;
  margin: 56px 0 40px;
  position: relative;
}
.mainTitle::after {
  content: "";
  width: 100px;
  height: 4px;
  background-color: #d7d7d7;
  position: absolute;
  left: 50%;
  bottom: -2px;
  transform: translateX(-50%);
}
.content {
  color: #333;
  font-size: 21px;
  line-height: 30px;
  margin-bottom: 32px;
  letter-spacing: 0.2px;
}
img {
  width: 86%;
}
</style>